import Seo from "@components/Seo";
import { withWebLayout } from "@components/web/Decorators";
import DownloadAppButton from "@components/web/DownloadAppButton";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import appleEditorsChoice from "./images/newFrontpage/apple-editors-choice.svg";
import baernIByen from "./images/newFrontpage/baern-i-byen.svg";
import logoWithText from "./images/newFrontpage/logo/logo-dark-text-beige-icon.svg";
import styles from "./index.module.scss";

export default withWebLayout(() => {
	const useStyles = makeStyles((theme) =>
		createStyles({
			mapContainerWhiteBackground: {
				backgroundColor: "#fff",
				paddingTop: "370px",
				[theme.breakpoints.down('xs')]: {
					paddingTop: "270px",
				}
			},
			mapContainerBlueBackground: {
				backgroundColor: "#62AED6",
				paddingBottom: "80px"
			}
		})
	);
	const classes = useStyles();

	return <FrontPageLayout
		includeFooter
		removeBlueBodyBackground
		withLegacyBackgroundColor
		withoutAppBar
	>
		<Seo
			title="Reshopper"
			description="Danmarks største markedsplads for børnefamilier. Køb og salg af secondhand fra familie til familie."
		/>

		<Container className={styles.welcomeSection}>
			<Container className={styles.textContainer}>
				<Grid container>
					<Grid item xs={5} sm={3} md={2} style={{ marginBottom: "10px" }}>
						<img alt="Reshopper logo" src={logoWithText} width={190} height={46} />
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={12}
						md={9}>
						<Typography
							variant="h1"
							style={{ marginBottom: "10px" }}>
							Børne&shy;familiernes markeds&shy;plads
						</Typography>
						<Typography
							variant="subtitle1"
							style={{ marginBottom: "10px" }}>
							En god handel. Igen og igen.
						</Typography>
						<Box component="nav">
							<Typography>
								Shop til <Link href="/dk/til-børn">børn</Link>, <Link href="/dk/til-mor">mor</Link> og <Link href="/dk/til-bolig">bolig</Link>
							</Typography>
						</Box>
						<Box className={styles.buttonContainer}>
							<DownloadAppButton />
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Container>

		<Container className={styles.infoSection}>
			<Grid
				container
				justifyContent="center"
				alignItems="flex-end">
				<Grid
					item
					xs={6}
					sm={4}
					md={2}
					style={{
						padding: "0 28px"
					}}>
					<img alt="Apple - Redaktionens udvalgte" src={appleEditorsChoice} width={194} height={96} loading="lazy" />
				</Grid>
				<Grid
					item
					xs={6}
					sm={4}
					md={2}
					style={{
						padding: "0 28px"
					}}>
					<img alt="Børn i byen - Årets familieapp" src={baernIByen} width={194} height={84} loading="lazy" />
				</Grid>
			</Grid>
			<Box style={{ textAlign: "center", paddingTop: "30px" }}>
				<Typography variant="h3">
					250.000 bruger Reshopper. <br /> Vær med i dag.
				</Typography>
				<Typography
					variant="body2"
					style={{
						marginTop: "30px",
						marginBottom: "40px"
					}}>
					Over 300.000 nye varer oprettes hver måned. <br /><br /> 100.000 varer sælges hver måned - mere end 2 i minuttet.
				</Typography>
				<DownloadAppButton />
			</Box>
		</Container>

	</FrontPageLayout>
});

